/* eslint-disable sort-keys */

import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { pageFadeInOut } from '@/modules/animationVariants'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import QuoteContainer from './one/QuoteContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const OnePage: React.FC = () => {
  const { t } = useTranslation()

  const content = {
    pretitle: t(
      'our-platform.one_super',
      'fill copy key: our-platform.one_super',
    ),
    title: t('our-platform.one_title', 'fill copy key: our-platform.one_title'),
    text: t('our-platform.one_body', 'fill copy key: our-platform.one_body'),
    links: [
      {
        link: t('our-platform.one_cta', 'fill copy key: our-platform.one_cta'),
        url: '/our-platform/foundation',
        variant: 'all',
      },
    ],
  }

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                <QuoteContainer />,
                <Text isMobile {...content} />,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>
                    <Text {...content} />
                  </Box>
                </Box>
                <Box
                  col={0.5}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <QuoteContainer />
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default OnePage
